<script lang="jsx">
/**
 * @component 邮寄信息抽屉
 */

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template, htmlDecode } from '@shein/common-function'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import AddressDrawer from './AddressDrawer'
import {
  TitleNav as STitleNav,
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'
import AddressCascaderIndex from 'public/src/pages/components/address/address_cascader_index'
import ShippingCosts from '../ShippingCostsV1.vue'
import ShippingDayPercent from 'public/src/pages/components/ShippingDayPercent'
import { mapState } from 'vuex'

dayjs.extend(utc)

const { lang, IS_RW } = gbCommonInfo

export default {
  name: 'ShippingDrawer',
  components: {
    STitleNav,
    STitleNavItem,
    PinCode: () => import('./PinCode'),
    AddressDrawer,
    AddressCascaderIndex,
    ShippingCosts,
    ShippingDayPercent
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    freeShippingInfo: {
      type: Object,
      default: () => ({})
    },
    largeShipStatus: {
      type: Number,
      default: 0
    },
    agingExtend: {
      type: Object,
      default: () => {}
    },
    showDescQuickShipTime: {
      type: [Boolean, String],
      default: false
    },
    quickShipHighlight: {
      type: Boolean,
      default: false
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    language: {
      type: Object,
      default: () => ({})
    },
    shippingLanguage: {
      type: Object,
      default: () => ({})
    },
    goodsDeliveryDate: {
      type: Object,
      default: () => ({})
    },
    shippingInfo: {
      type: Object,
      default: () => ({})
    },
    deliveryInfo: {
      type: Object,
      default: () => ({})
    },
    returnPolicy: {
      type: Array,
      default: () => []
    },
    delayWarn: {
      type: Array,
      default: () => []
    },
    shippingPolicy: {
      type: Array,
      default: () => []
    },
    addressList: {
      type: Array,
      default: () => []
    },
    needRequest: {
      type: Boolean,
      default: false
    },
    isStore: {
      type: Boolean,
      default: false
    },
    mallName: {
      type: String,
      default: ''
    },
    showMallSite: {
      type: Boolean,
      default: false
    },
    shippingPosition: {
      type: [String, Number],
      default: ''
    },
    abtConfig: {
      type: Object,
      default: () => ({})
    },
    shippingStyleV1: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      lang,
      IS_RW,
      isShow: false,
      isShowDeliveryAnnounce: true,
      showPinCode: false,
      showAddress: false,
      showAddressList: false
    }
  },
  computed: {
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapState('productDetail/ShippingEnter', ['address_cache', 'address_cookie']),
    title() {
      if (this.MAIN_BFF_APOLLO.v5) {
        let { address_cache, address_cookie: addressCookie } = this
        let {countryId, stateId, cityId, districtId} = addressCookie
      return [address_cache?.[countryId] || '',
        address_cache?.[stateId] || '',
        address_cache?.[cityId] || '',
        address_cache?.[districtId]].join(' ')
      } else {
        let { country, state_province, city, district } = this.shippingInfo
        return [country, state_province, city, district].join(' ')
      }
    },
    showShippingDayPercent() {
      return !!this.abtConfig?.showShippingDayPercent
    },
    showShippingMethods() {
      if (!this.shippingPolicy.length) return false

      const freeShipping = this.shippingPolicy.filter(item => {
        return (
          Number(item.hasZeroThreshold) === 1 ||
          (Number(item?.startingPriceObj?.amount) === 0 &&
            Number(item?.stepPriceObj?.amount) === 0) ||
          (Number(item?.startingPriceObj?.amount) > 0 &&
            Number(item?.fullAmount?.amount) > 0)
        )
      })
      if (freeShipping.length) {
        return true
      } else {
        return false
      }
    },
    showShippingCostsTips() {
      const steps = this.shippingPolicy.filter(item => {
        return !(
          Number(item.hasZeroThreshold) === 1 ||
          (Number(item.startingPriceObj.amount) === 0 &&
            Number(item.stepPriceObj.amount) === 0) ||
          Number(item.shippingMold) === 1 ||
          Number(item.lastPriceObj.amount) ===
            Number(item.startingPriceObj.amount)
        )
      })
      if (steps.length) {
        return true
      } else {
        return false
      }
    },
    notSupportFree() {
      return this.shippingPolicy.length
        ? this.shippingPolicy.every(i => i.is_support_free_shipping == 0)
        : false
    },
    drawerTitle() {
      const { language, template, mallName, showMallSite } = this
      if (showMallSite) {
        return template(mallName, language['SHEIN_KEY_PWA_20917'])
      } else {
        return `${language['SHEIN_KEY_PWA_16266']}:`
      }
    },
    hasShippingPercent() {
      return !!this.shippingPolicy?.find(item => item?.day_percents?.length) 
    }
  },
  watch: {
    value(newVal) {
      newVal && this.dayPercentToScreen()
      this.isShow = newVal
    },
    isShow(newVal) {
      this.$emit('input', newVal)
    }
  },
  mounted() {
    if (this.value) {
      this.isShow = true
    }
  },
  methods: {
    dayPercentToScreen() {
      if (this.shippingPosition >= 0 && this.shippingPosition !== '') {
        this.$nextTick(() => {
          this.$refs['SHIPPING_DAY_PERCENT_REF']?.[0]?.$el?.scrollIntoView?.()
        })
      }
    },
    getShippingOutsideText(policy) {
      // date_tpye (1=工作日，0=自然日)
      const isBusiness = policy.date_type == 1
      const customDayUnit = isBusiness
        ? this.language.SHEIN_KEY_PWA_23614
        : this.language.SHEIN_KEY_PWA_23615
      return policy.desc_type == 1
        ? this.computedExpectTime(policy)
        : policy.transport_interval
          ? policy.transport_interval + ' ' + htmlDecode({ text: customDayUnit })
          : ''
    },
    computedExpectTime: function (item) {
      if (item.expectTime == '') {
        return ''
      }
      let expectTime = this.computedTime(item)
      return template(expectTime, this.language.SHEIN_KEY_PWA_16823)
    },
    computedTime: function(item) {
      const originTime = item.expectTime 
      if (originTime == '') {
        return ''
      }
      let resultTime = ''
      let timeFormat = ''
      if (['en', 'il', 'us', 'asia'].includes(this.lang)) {
        timeFormat = 'MM/DD/YYYY'
      } else if (
        [
          'au',
          'mx',
          'hk',
          'br',
          'ca',
          'eur',
          'cl',
          'ma',
          'sg',
          'za',
          'nz',
          'pl',
          'ph',
          'my',
          'ch'
        ].includes(this.lang)
      ) {
        timeFormat = 'DD/MM/YYYY'
      } else if (['jp', 'ar', 'tw', 'maar'].includes(this.lang)) {
        timeFormat = 'YYYY/MM/DD'
      } else {
        timeFormat = 'DD/MM/YYYY'
      }

      if (originTime.includes('-')) {
        const [start, end] = originTime.split('-')
        const startDate = dayjs(item.currentTime)
          .add(8, 'hour')
          .add(start, 'day')
        const endDate = dayjs(item.currentTime).add(8, 'hour').add(end, 'day')
        resultTime = `${startDate.utc().format(timeFormat)} - ${endDate
          .utc()
          .format(timeFormat)}`
      } else {
        resultTime = dayjs(item.currentTime)
          .add(8, 'hour')
          .add(originTime, 'day')
          .utc()
          .format(timeFormat)
      }
      return resultTime
    },
    openPinCode(is_change) {
      this.showPinCode = true
      daEventCenter.triggerNotice({
        daId: is_change ? '1-6-4-11' : '1-6-4-12'
      })
    },
    handlerShippingTo() {
      if (this.addressList.length) {
        this.showAddressList = true
      } else {
        this.goSelectUpper()
      }
    },
    goSelectUpper() {
      this.$refs.addressCascaderVue.updateUI(this.shippingInfo.address_id)
      this.showAddressList = false
      this.showAddress = true
    },
    getOtherAddress(address) {
      const {
        city,
        city_id,
        state_province,
        country_id,
        district,
        address_id,
        district_id,
        state_id
      } = address
      const params = {
        city: city,
        city_id: city_id,
        state_province: state_province,
        country_id: country_id,
        address_id: address_id,
        district: district,
        district_id: district_id,
        state_id: state_id
      }
      this.$emit('updateAddress', params)
    },
    selectAddress(params) {
      this.showAddressList = false
      // 选择地址簿中地址后，自定义地址清空
      this.$refs.addressCascaderVue.clearData()
      this.$emit('updateAddress', params)
    },
    loginSuccess() {
      this.showAddress = false
      this.$emit('updateAddress', {})
    },
    getFooterText(content) {
      if (!content) return
      let reg = this.IS_RW
        ? /\/Free-Shipping-a-471.html/
        : /\/Shipping-Info-a-280.html/
      let text = this.IS_RW
        ? '/Free-Shipping-a-471.html'
        : '/Shipping-Info-a-280.html'
      if (this.abtConfig?.showCostTopTime) {
        const hasShop = this.shippingPolicy.find(
          item => !!['express_shoptransit', 'shop_transit'].includes(item.type)
        )
        const shopContent = `<p><i class="suiiconfont sui_icon_environmental_16px green"></i> ${this.language.SHEIN_KEY_PWA_26076}</p>`
        return `
        ${hasShop ? shopContent : ''}
        <a href="${this.langPath}${text}">${
          this.language.SHEIN_KEY_PWA_15685
        }</a>`
      }
      if (this.abtConfig.showDeletemess) {
        return `<a href="${this.langPath}${text}">${this.language.SHEIN_KEY_PWA_15685}</a>`
      }
      return content
        .replace(/<br[\s]*>|<br[\s]*\/>/g, '')
        .replace(reg, `${this.langPath}${text}`)
    },
    submitPinCode({ goodsDeliveryDate, deliveryInfo, params }) {
      this.$emit('updateDelivery', {
        goodsDeliveryDate,
        deliveryInfo
      })
      this.$emit('updateAddress', params)
    },
    environmentalToast(title) {
      this.$toast({
        content: template(title, this.language.SHEIN_KEY_PWA_29430),
        position: 'center'
      })
    },
    getDayPercentDes(item, largeShipStatus) {
      return this.$parent.getDayPercentDes(item, largeShipStatus)
    },
    dayPercentChange({ val }) {
      daEventCenter.triggerNotice({
        daId: '1-6-4-61',
        extraData: {
          tab_title: this.shippingPolicy[val]?.title || ''
        }
      })
    },
    isShowFree(item) {
      let { isProductFreeShipping, isCouponFreeShipping, freeShippingMethods } = this.freeShippingInfo
      if(Number(item.has_zero_threshold) == 1 && item.is_support_free_shipping != 0){ //0门槛免邮 
        return true
      }else if ((isProductFreeShipping || isCouponFreeShipping) && freeShippingMethods.includes(item.transport_type) && this.abtConfig.showFreeinQuire){ //商品免邮 & 优惠券免邮
        return true
      }else if (item.isPromotionFreeShipping && this.abtConfig.showFreeinQuire){ //活动免邮
        return true
      }
      return false
    }
  },
  render() {
    let { language, shippingStyleV1, abtConfig, largeShipStatus } = this
    const headerDom = () => {
      return (
        <s-title-nav title={language.SHEIN_KEY_PWA_15736}>
          <s-title-nav-item
            slot="preblock"
            class="j-icon-back"
            icon-class="suiiconfont sui_icon_nav_back_24px"
            onClick={() => (this.isShow = false)}
          />
        </s-title-nav>
      )
    }
    const descQuickShipTimeDom = () => {
      return this.showDescQuickShipTime ? (
        <div
          {...{ directives: [{ name: 'expose', value: { id: '1-6-4-52' } }] }}
          class="shipping-drawer__quickship-disclaimer"
        >
          <span domPropsInnerHTML={abtConfig.deliveryDialogNTips}></span>
        </div>
      ) : null
    }
    const annouceDom = () => {
      if (
        this.lang == 'in' &&
        this.isShowDeliveryAnnounce &&
        this.goodsDeliveryDate.is_display &&
        this.goodsDeliveryDate.is_display === '1' &&
        (this.goodsDeliveryDate.is_shield === '0' ||
          this.goodsDeliveryDate.is_shield === '2')
      ) {
        let className = {
          'shipping-drawer__annouce': true,
          warning: this.goodsDeliveryDate.is_shield === '2',
          tip: this.goodsDeliveryDate.is_shield === '0'
        }
        let text = null
        text =
          this.goodsDeliveryDate.is_shield === '0' &&
          `${language.SHEIN_KEY_PWA_17289} ${language.SHEIN_KEY_PWA_17290}`
        text =
          this.goodsDeliveryDate.is_shield === '2' &&
          `${language.SHEIN_KEY_PWA_17291} ${language.SHEIN_KEY_PWA_17292}`
        return (
          <div class={className}>
            <i class="iconfont icon-BulletinBoard"></i>
            <span>{text}</span>
            <i
              class="iconfont icon-close"
              onClick={() => (this.isShowDeliveryAnnounce = false)}
            ></i>
          </div>
        )
      }
    }

    const agingInformatio = (policy = {}, showShippingAllTime = false) => {
      if (showShippingAllTime) {
        return (
          <div>
            <p>
              {template(
                this.computedTime(policy),
                this.language.SHEIN_KEY_PWA_29551
              )}
            </p>
            {this.showShippingDayPercent && (
              <p
                class="shipping-drawer__day-percent"
                domPropsInnerHTML={this.getDayPercentDes(policy)}
              ></p>
            )}
          </div>
        )
      } else if (largeShipStatus) {
        return (
          <p>
            {policy.time_type === 2 ? policy.aging_date : this.getShippingOutsideText(policy)}
            <br/>
            {this.showShippingDayPercent && (
              <p
                class="shipping-drawer__day-percent"
                domPropsInnerHTML={this.getDayPercentDes(policy, largeShipStatus)}
              ></p>
            )}
          </p>
        )

      } else if (shippingStyleV1 && policy.desc_type == 1) {
        const label = !this.quickShipHighlight ? <span>{language.SHEIN_KEY_PWA_30099}</span> : null
        const info = !this.quickShipHighlight ? <span class="black" domPropsInnerHTML={policy.aging_is_expose == 1 ? policy.aging_date : ''}></span> : null
        return (<p>
          {label}
          {info}
          <br/>
          <span domPropsInnerHTML={this.getDayPercentDes(policy)}></span>
        </p>)
      } else  {
        return (
          <div>
            {!this.quickShipHighlight &&
              <p>{this.getShippingOutsideText(policy)}</p>
            }
            {this.showShippingDayPercent && 
              <p
                class="shipping-drawer__day-percent"
                domPropsInnerHTML={this.getDayPercentDes(policy)}
              ></p>
            }
          </div>
        )
      }
    }

    // 时效信息
    const agingInformationContent = (policy) => {
      const showShippingAllTime = this.abtConfig.showAllShipTimeShow
      if (showShippingAllTime && !this.quickShipHighlight) {
        if(largeShipStatus){
          return (
            <td  class="shipping-drawer__all-time">
              <p >{policy.time_type === 1 ? this.getShippingOutsideText(policy) : this.getShippingOutsideText({ ...policy, transport_interval: policy.sub_transport_time }) } </p>
              <div class="shipping-drawer__all-time">{policy.time_type !== 1 ? this.computedExpectTime(policy) : this.computedExpectTime({ ...policy, transport_interval: policy.sub_transport_time })}</div>
              {this.showShippingDayPercent && (
                <p
                  class="shipping-drawer__day-percent"
                  domPropsInnerHTML={this.getDayPercentDes(policy, largeShipStatus)}
                ></p>
              )}
            </td>
          )
        }

        return (
          <td class="shipping-drawer__all-time">
            <p>{`${
              policy.desc_type == 1
                ? policy.expectTime
                : policy.transport_interval
            } ${
              policy.date_type == 1
                ? this.language.SHEIN_KEY_PWA_23614
                : this.language.SHEIN_KEY_PWA_23615
            }`}</p>
            {agingInformatio(policy, showShippingAllTime)}
          </td>
        )
      } else {
        return (
          <td>
            {this.quickShipHighlight && this.descQuickShipTime && (
              <p
                {...{
                  directives: [{ name: 'expose', value: { id: '1-6-4-51' } }]
                }}
                class="shipping__quickship-tip highlight"
                domPropsInnerHTML={this.descQuickShipTime}
              ></p>
            )}
            {agingInformatio(policy)} 
          </td>
        )
      }
    }

    const returnPolicyDom = () => {
      let ergodicDoms = this.returnPolicy.filter(item => item.type_id == '2')
      const costsConfig = { showCostTopTime: this.abtConfig?.showCostTopTime, shippingStyleV1: this.shippingStyleV1 }
      const needHide = !this.abtConfig?.showCostTopTime
      const showShippingMethodsDom = () => {
        // if (this.abtConfig?.showCostTopTime || this.abtConfig.showTransportaop)
        //   return null
        // if (this.notSupportFree) return null
        // if (!this.showShippingMethods) return null
        
        if(this.largeShipStatus){
          return <div style="height:10px;width:100%"></div>
        }

        let result = []
        this.shippingPolicy.forEach(val => {
          if(this.isShowFree(val)){
            result.push(
              <span class="shipping-drawer__deliveryTitle">
                {this.shippingLanguage[val.type + '_free']}
              </span>
            )
          } else if (val.is_support_free_shipping && val.startingPriceObj) {
            // 运费=0
            if (
              Number(val.startingPriceObj.amount) === 0 &&
                val.stepPriceObj &&
                Number(val.stepPriceObj.amount) === 0
            ) {
              result.push(
                <span class="shipping-drawer__deliveryTitle">
                  {this.shippingLanguage[val.type + '_free']}
                </span>
              )
            }
            // 运费≠0
            if (
              Number(val.startingPriceObj.amount) > 0 &&
                val.fullAmount.amount > 0
            ) {
              result.push(
                <span
                  class="shipping-drawer__deliveryTitle"
                  domPropsInnerHTML={`${template(
                    val.fullAmount.amountWithSymbol,
                    this.shippingLanguage[val.type]
                  )}`}
                ></span>
              )
            }
          }
        })
        return (
          (result.length && !this.abtConfig?.showCostTopTime && !this.abtConfig?.showTransportaop) ?
            <ul>
              {result.map(item => (
                <li>{item}</li>
              ))}
            </ul>
            : !result.length && this.notSupportFree ?
              <div class="shipping-drawer__notfree">{language.SHEIN_KEY_PWA_18722}</div> 
              : null
        )
      }
      return ergodicDoms.map((item, key) => {
        return (
          <div key={key}>
            {this.lang == 'in' ? (
              <div class="shipping-drawer__to">
                <div class="shipping-drawer__info">
                  <div class="shipping-drawer__title">{this.drawerTitle}</div>
                  <p class="shipping-drawer__text">
                    {this.deliveryInfo.city
                      ? this.deliveryInfo.country +
                        ',' +
                        this.deliveryInfo.enterPincode +
                        ',' +
                        this.deliveryInfo.city
                      : this.shippingInfo.country}
                  </p>
                </div>
                <button
                  class="mshe-btn-sec mshe-btn-xs"
                  onClick={e => {
                    this.openPinCode(this.deliveryInfo.city)
                    e.stopPropagation()
                  }}
                >
                  {this.deliveryInfo.city
                    ? 'Change'
                    : language.SHEIN_KEY_PWA_16267}
                </button>
              </div>
            ) : (
              <div
                class="shipping-drawer__to"
                da-event-click="1-6-4-7"
                onClick={this.handlerShippingTo}
              >
                <div class="shipping-drawer__info">
                  <div class="shipping-drawer__title">
                    <span class={{ main: this.isStore }}>
                      {this.drawerTitle}
                    </span>
                  </div>
                  <p class="shipping-drawer__text">
                    {this.title}
                  </p>
                </div>
                <i class="suiiconfont sui_icon_more_right_16px_2"></i>
              </div>
            )}
            <div class="shipping-drawer__delivery delivery-info">
              <h4>{language.SHEIN_KEY_PWA_15217}</h4>
              {this.showDescQuickShipTime && !this.quickShipHighlight ? (
                <div
                  {...{
                    directives: [{ name: 'expose', value: { id: '1-6-4-51' } }]
                  }}
                  class="shipping__quickship-tip popup"
                  domPropsInnerHTML={this.descQuickShipTime}
                ></div>
              ) : null}
              {/* {this.notSupportFree ? (
                <div>{language.SHEIN_KEY_PWA_18722}</div>
              ) : (
                showShippingMethodsDom()
              )} */}
              {showShippingMethodsDom()} 
              <table>
                <thead>
                  <tr>
                    <th class="shipping-drawer__first">
                      <p class="none-text-transform">
                        {language.SHEIN_KEY_PWA_15218}
                      </p>
                    </th>
                    <th class="shipping-drawer__second">
                      <p class="none-text-transform">
                        {language.SHEIN_KEY_PWA_14977}
                      </p>
                    </th>
                    <th class="shipping-drawer__last">
                      <p class="none-text-transform">
                        {language.SHEIN_KEY_PWA_16405}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.shippingPolicy.map(policy => {
                      let title = `${policy.title.charAt(0).toUpperCase()}${policy.title.slice(1)?.toLowerCase()}`
                      return (
                        <tr>
                          <td class="shipping-drawer__shipping-method">
                            <p>{['shop_transit', 'express_shoptransit'].includes(policy.type) && this.abtConfig.showEnvironprotect && <i class="suiiconfont sui_icon_environmental_16px green" vOn:click={ () => this.environmentalToast(title) }></i>}{title}</p>
                          </td>

                          {agingInformationContent(policy)}
                          <td class="shipping-drawer__costs">
                            <ShippingCosts config={costsConfig} item={policy} language={language} need-hide={needHide} show-transportaop={this.abtConfig.showTransportaop}  is-show-free={this.isShowFree(policy)}/>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              {this.largeShipStatus ? (
                <div class="shipping-drawer__largeship-tips">
                  {this.largeShipStatus === 1 ? language.SHEIN_KEY_PWA_32037 : language.SHEIN_KEY_PWA_32036}
                </div>
              ) : (
                <div>
                  {this.showShippingCostsTips ? (
                    <div class="shipping-drawer__tips">
                      {language.SHEIN_KEY_PWA_17442}
                    </div>
                  ) : null}
                  {this.delayWarn
                    .filter(item => !!item.supple_desc)
                    .map(warn => {
                      return (
                        <div class="shipping-drawer__warn">
                          {warn.title}&nbsp;:&nbsp;
                          <span domPropsInnerHTML={warn.supple_desc}></span>
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
            {this.showShippingDayPercent && this.hasShippingPercent ? (
              <ShippingDayPercent
                ref="SHIPPING_DAY_PERCENT_REF"
                language={language}
                uppercase={true}
                shipping-methods={this.shippingPolicy}
                active-index={this.shippingPosition}
                need-filter={true}
                title-padding={true}
                class="shipping-drawer__percent"
                onChange={this.dayPercentChange}
              />
            ) : null}
            <div
              class="shipping-drawer__footer"
              domPropsInnerHTML={this.getFooterText(item.content)}
            ></div>
          </div>
        )
      })
    }
    return (
      <div>
        <SDrawer
          no-header={true}
          type="full"
          visible={this.isShow}
          on={{ 'update:visible': v => (this.isShow = v) }}
          direction="rtl"
        >
          <div class="mshe-z-transform1 transform-fixed mshe-bg-container shipping-drawer">
            {headerDom()}
            <section class="user-select-return-policy shipping-drawer__content">
              {descQuickShipTimeDom()}
              {annouceDom()}
              {returnPolicyDom()}
            </section>
          </div>
        </SDrawer>
        <AddressCascaderIndex
          ref="addressCascaderVue"
          addrshow={this.showAddress}
          language={language}
          onLoginsuccess={this.loginSuccess}
          onCancel={() => (this.showAddress = false)}
          onOtheraddresscallback={this.getOtherAddress}
        />
        <AddressDrawer
          v-model={this.showAddressList}
          language={language}
          address-list={this.addressList}
          shipping-info={this.shippingInfo}
          onGoSelectUpper={this.goSelectUpper}
          onSelectAddress={this.selectAddress}
        />
        {this.lang === 'in' ? (
          <PinCode
            v-model={this.showPinCode}
            language={language}
            onSubmit={this.submitPinCode}
          />
        ) : null}
      </div>
    )
  }
}
</script>

<style lang="less">
.shipping-drawer {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6 !important; /* stylelint-disable-line declaration-no-important */
  &__header {
    h4 {
      /* rw:begin */
      font-family: Adieu;
    }
    .sui_icon_nav_back_24px {
      /* rw:begin */
      .font-dpr(48px) !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  &__content {
    padding: 0;
    height: 100%;
  }
  &__shipping-method {
    line-height: normal;
    p {
      text-transform: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  &__all-time {
    color: var(---sui_color_gray_dark1, #222);
    line-height: normal;
    color: var(---sui_color_gray_dark1, #222);
    p:first-child {
      margin-bottom: 2px;
    }
  }
  &__quickship-disclaimer {
    background: @sui_color_prompt_warning;
    padding: 0.32rem;
    .font-dpr(24px);
    color: @sui_color_gray_dark1;
  }
  &__largeship-tips{
    margin-top: 8px;
    color: var(---sui_color_gray_dark2, #666);
  }

  &__annouce {
    display: flex;
    align-items: center;
    padding: 0.16rem 0;

    [class*='iconfont'] {
      color: #666;
      padding: 0.32rem;
      .font-dpr(28px);

      &.icon-close {
        color: #999;
        .font-dpr(24px);
      }
    }

    &.warning {
      background: #fff1d5;
    }

    &.tip {
      background: #d9eaff;
    }
  }

  &__to {
    position: relative;
    .flexbox();
    .align-center();
    .space-between();
    padding: 0.24rem 0.32rem 0.2133rem;
    background: #fff;
    margin-top: 0.22rem;

    .mshe-btn-sec {
      .font-dpr(24px);
      color: #222;
      text-transform: capitalize !important; /* stylelint-disable-line declaration-no-important */
      font-weight: 400;
      /*rw:begin*/
      border-radius: 999px;
      border-color: #ccc !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &__title {
    .font-dpr(24px);
    color: #999999;
    .main {
      color: #198055;
    }
  }

  &__text {
    .font-dpr(28px);
    color: #222;
    font-weight: 400;
  }

  &__info {
    color: #222;
    .font-dpr(28px);
    line-height: 0.51rem;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 8.6rem;
  }

  &__day {
    background: #fff;
    margin-top: 0.32rem;
    padding-bottom: 0.32rem;
  }

  &__delivery {
    padding: 0.22rem 0.32rem 0.53rem;
    background-color: #fff;
    margin-top: 0.22rem;
    ul {
      padding: 0.423rem 0;
    }
    li {
      .font-dpr(24px);
      color: #222;
      text-transform: capitalize;
    }
    table {
      width: 100%;
      border: 1px solid #e5e5e5;
      border-collapse: collapse;
      margin: 0.11rem 0;
      .font-dpr(24px);
      .txt-l();
      .padding-l(0.32rem);
    }
    thead {
      background-color: #f6f6f6;
      color: #222;
    }
    tbody {
      color: #666666;
    }
    tr {
      min-height: 0.96rem;
      height: 0.96rem;
    }
    td,
    th {
      border: 1px solid #e5e5e5;
      padding: 0.32rem;
      font-weight: 400;
    }
    p {
      text-transform: capitalize;
    }
    th.shipping-drawer__first {
      max-width: 2.53333rem;
      overflow: hidden;
    }
    th.shipping-drawer__second {
      min-width: 4.4rem;
    }
    th.shipping-drawer__last {
      min-width: 2.5rem;
    }
    td.shipping-drawer__costs {
      // font-weight: bold;
    }
    .shipping-drawer__day {
      padding: 0 24/75rem;
    }
    .shipping-drawer__day-percent {
      color: @sui_color_gray_dark1;
    }
    h4 {
      /* rw:begin */
      font-family: Adieu;
    }
  }
  &__notfree{
    padding: 0.423rem 0;
  }
  &__tips {
    margin-top: 0.43rem;
  }
  &__warn {
    padding-top: 0.32rem;
    word-break: break-word;
  }
  &__deliveryTitle {
    color: @sui_color_safety;
    text-transform: uppercase;
    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: @sui_color_safety;
      vertical-align: middle;
      border-radius: 2px;
      .margin-r(0.1rem);
    }
  }
  &__footer {
    .font-dpr(24px);
    padding: 20/75rem 0.32rem;
    p {
      color: #666666;
    }
    u {
      display: block;
      text-decoration: none;
    }
    a {
      color: @sui_color_link;
      text-decoration: none;
    }
  }
  &__percent {
    margin-top: 16/75rem;
    background-color: @sui_color_white;
    padding-bottom: 0.32rem;
  }
  .free {
    color: @sui_color_highlight;
    // font-weight: bold;
  }
  .green {
    color: @sui_color_safety;
    font-weight: normal;
  }
  .black {
    color: @sui_color_black;
  }
  .none-text-transform {
    text-transform: none;
    line-height: normal;
  }
}
</style>
