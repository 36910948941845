<template>
  <div :class="{'shipping-costs' : showTransportaop}">
    <!-- 后台开启0门槛免邮/商品免邮/活动免邮 -->
    <template v-if="isShowFree">
      <div
        class="free"
        :class="{'green': config?.showCostTopTime || config?.shippingStyleV1}"
      >
        {{ freeLang }}
      </div>
    </template>
    <!-- 
      后台未开启0门槛免邮 
      shipping_mold=1按件数步价, shipping_mold=2按件数, shipping_mol=3按重量, shipping_mol=4按金额 
    -->
    <template v-else>
      <!-- 
        当 “free_price_num、 full_amount 、full_weight ”都为0时 
      -->
      <template v-if="Number(item.free_price_num) === 0 && Number(item.full_amount && item.full_amount.amount) === 0 && Number(item.full_weight) === 0 && item.is_support_free_shipping != 0">
        <!-- 计价方式=按件数步价 shipping_mold = 1 -->
        <template v-if="Number(item.shipping_mold) === 1">
          <!-- / 默认价格为0 -->
          <template v-if="Number(item.starting_price) === 0">
            <!-- // 默认步价为0 -->
            <div
              v-if="Number(item.step_price) === 0"
              class="free"
              :class="{'green': config?.shippingStyleV1 }"
            >
              {{ freeLang }}
            </div>
            <!-- // 默认步价不为0 情况不存在-->
          </template>
          <!-- / 默认价格>0 -->
          <template v-else>
            <!-- // 默认步价为0 -->
            <template v-if="Number(item.step_price) === 0">
              <div> 
                {{ item.starting_price_obj.amountWithSymbol }} 
                <div v-if="Number(item.full_amount.amount) > 0&&showTransportaop&&item.is_support_free_shipping != 0">
                  <span class="product-ship__priceToFree">{{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_28237) }}</span>
                </div>
              </div>
            </template>
            <!-- // 默认步价不为0 -->
            <template v-else>
              <!-- 起步数量 > 0 -->
              <div v-if="Number(item.starting_num) > 0 && item.starting_price_obj && item.step_price_obj">
                {{ template(item.starting_num, item.starting_price_obj.amountWithSymbol, item.step_price_obj.amountWithSymbol, language.SHEIN_KEY_PWA_16591) }}
              </div>
              <!-- 起步数量 = 0 -->
              <div v-else-if="item.step_price_obj">
                {{ template(item.step_price_obj.amountWithSymbol, language.SHEIN_KEY_PWA_16589) }}
              </div>
            </template>
          </template>
        </template>
        <!-- 计价方式!=按件数步价 shipping_mold != 1 -->
        <template v-else>
          <!-- 一个阶梯价格 -->
          <template v-if="shippingDetail(item).length === 1">
            <!-- 默认价格为0 -->
            <div
              v-if="Number(item.starting_price) === 0"
              class="free"
              :class="{'green': config?.shippingStyleV1 }"
            >
              {{ freeLang }}
            </div>
            <!-- 默认价格不为0 -->
            <div v-else>
              {{ item.starting_price_obj.amountWithSymbol }} 
              <div v-if="Number(item.full_amount.amount) > 0&&showTransportaop&&item.is_support_free_shipping != 0">
                <span class="product-ship__priceToFree">{{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_28237) }}</span>
              </div>
            </div>
          </template>
          <!-- 多个阶梯价格 -->
          <template v-else-if="shippingDetail(item).length > 1">
            <div
              v-for="(val, index) in shippingDetail(item)"
              :key="index"
            >
              <template v-if="index > 0">
                <!-- 阶梯费用开始 -->
                <template v-if="index === 1">
                  <div> {{ template(shippingDetail(item)[0].price.amountWithSymbol, formatUnit(val, item.shipping_mold), moldLang['mold'+item.shipping_mold].less) }} </div>
                </template>
                <template v-else>
                  <div> {{ template(shippingDetail(item)[index-1].price.amountWithSymbol, formatUnit(shippingDetail(item)[index-1], item.shipping_mold), formatUnit(val, item.shipping_mold), moldLang['mold'+item.shipping_mold].between) }} </div>
                </template>
                <!-- 最后一个阶梯 -->
                <template v-if="shippingDetail(item).length - 1 === index"> 
                  <div> {{ template(val.price.amountWithSymbol, formatUnit(val, item.shipping_mold), moldLang['mold'+item.shipping_mold].upperLimit) }}  </div>
                </template>
              </template>
            </div>
          </template>
        </template>
      </template>
      <!-- 
        当 “free_price_num、 full_amount 、full_weight" 有一个＞0 
        (“free_price_num、 full_amount 、full_weight" 有多个＞0，这种情况不存在) 
      -->
      <template v-else>
        <!-- shipping_mold = 1 -->
        <template v-if="Number(item.shipping_mold) === 1">
          <!-- / 默认价格为0 -->
          <template v-if="Number(item.starting_price) === 0">
            <!-- // 默认步价位0 -->
            <div
              v-if="Number(item.step_price) === 0"
              class="free"
              :class="{'green': config?.shippingStyleV1 }"
            >
              {{ freeLang }}
            </div>
            <!-- // 默认步价位不为0，情况不存在 -->
          </template>
          <!-- / 默认价格不为0 -->
          <template v-else>
            <!-- // 默认步价为0 -->
            <template v-if="Number(item.step_price) === 0">
              <div> 
                {{ item.starting_price_obj.amountWithSymbol }} 
                <div v-if="Number(item.full_amount.amount) > 0&&showTransportaop&&item.is_support_free_shipping != 0">
                  <span class="product-ship__priceToFree">{{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_28237) }}</span>
                </div>
              </div>
              <!-- 免邮门槛 -->
              <div
                v-if="Number(item.free_price_num) > 0 && item.is_support_free_shipping != 0"
                class="free"
                :class="{'green': config?.shippingStyleV1 }"
              >
                {{ template(item.free_price_num, language.SHEIN_KEY_PWA_16415) }}
              </div>
              <div
                v-else-if="Number(item.full_amount.amount) > 0 && !needHide && item.is_support_free_shipping != 0"
                class="free"
                :class="{'green': config?.showCostTopTime}"
              >
                {{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_16407) }}
              </div>
              <div
                v-else-if="Number(item.full_weight) > 0 && item.is_support_free_shipping != 0"
                class="free"
                :class="{'green': config?.shippingStyleV1 }"
              >
                {{ template(`${Number(item.full_weight).toFixed(2)}g` , language.SHEIN_KEY_PWA_16412) }}
              </div>
            </template>
            <!-- // 默认步价不为0 -->
            <template v-else>
              <!-- 起步数量 > 0 -->
              <div v-if="Number(item.starting_num) > 0 && item.starting_price_obj && item.step_price_obj">
                {{ template(item.starting_num, item.starting_price_obj.amountWithSymbol, item.step_price_obj.amountWithSymbol, language.SHEIN_KEY_PWA_16591) }}
              </div>
              <!-- 起步数量 = 0 -->
              <div v-else-if="item.step_price_obj">
                {{ template(item.step_price_obj.amountWithSymbol, language.SHEIN_KEY_PWA_16589) }}
              </div>
              <!-- 免邮门槛 -->
              <div
                v-if="Number(item.free_price_num) > 0 && item.is_support_free_shipping != 0"
                class="free"
                :class="{'green': config?.shippingStyleV1 }"
              >
                {{ template(item.free_price_num, language.SHEIN_KEY_PWA_16415) }}
              </div>
              <div
                v-else-if="Number(item.full_amount.amount) > 0 && !needHide && item.is_support_free_shipping != 0"
                class="free"
                :class="{'green': config?.showCostTopTime}"
              >
                {{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_16407) }}
              </div>
              <div
                v-else-if="Number(item.full_weight) > 0 && item.is_support_free_shipping != 0"
                class="free"
                :class="{'green': config?.shippingStyleV1 }"
              >
                {{ template(`${Number(item.full_weight).toFixed(2)}g` , language.SHEIN_KEY_PWA_16412) }}
              </div>
            </template>
          </template>
        </template>
        <!-- 
          shipping_mold != 1 
          此时需要判断免邮门槛是否与阶梯价格相同
        -->
        <template v-else> 
          <!-- 一个阶梯价格 -->
          <template v-if="shippingDetail(item).length === 1">
            <!-- 默认价格为0 -->
            <template v-if="Number(item.starting_price) === 0">
              <div 
                class="free"
                :class="{'green': config?.shippingStyleV1 }"
              >
                {{ freeLang }}
              </div>
            </template>
            <!-- 默认价格不为0 -->
            <template v-else>
              <!-- 免邮门槛 -->
              <template v-if="Number(item.free_price_num) > 0">
                <div v-if="Number(item.shipping_mold) === 2"> 
                  {{ template(item.starting_price_obj.amountWithSymbol, '0', item.free_price_num, language.SHEIN_KEY_PWA_16414) }}
                </div>
                <div v-else>
                  {{ item.starting_price_obj.amountWithSymbol }} 
                  <div v-if="Number(item.full_amount.amount) > 0&&showTransportaop&&item.is_support_free_shipping != 0">
                    <span class="product-ship__priceToFree">{{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_28237) }}</span>
                  </div>
                </div>
                <div
                  v-if="item.is_support_free_shipping != 0"
                  class="free"
                  :class="{'green': config?.shippingStyleV1 }"
                >
                  {{ template(item.free_price_num, language.SHEIN_KEY_PWA_16415) }}
                </div>
              </template>
              <template v-else-if="Number(item.full_amount.amount) > 0">
                <div v-if="Number(item.shipping_mold) === 4"> 
                  {{ template(item.starting_price_obj.amountWithSymbol, '0', item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_16409) }}
                </div>
                <div v-else>
                  {{ item.starting_price_obj.amountWithSymbol }} 
                  <div v-if="Number(item.full_amount.amount) > 0&&showTransportaop&&item.is_support_free_shipping != 0">
                    <span class="product-ship__priceToFree">{{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_28237) }}</span>
                  </div>
                </div>
                <div
                  v-show="!needHide&&item.is_support_free_shipping != 0"
                  class="free"
                  :class="{'green': config?.showCostTopTime}"
                >
                  {{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_16407) }}
                </div>
              </template>
              <template v-else-if="Number(item.full_weight) > 0">
                <div v-if="Number(item.shipping_mold) === 3"> 
                  {{ template(item.starting_price_obj.amountWithSymbol, '0g', `${Number(item.full_weight).toFixed(2)}g`, language.SHEIN_KEY_PWA_16411) }}
                </div>
                <div v-else>
                  {{ item.starting_price_obj.amountWithSymbol }} 
                  <div v-if="Number(item.full_amount.amount) > 0&&showTransportaop&&item.is_support_free_shipping != 0">
                    <span class="product-ship__priceToFree">{{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_28237) }}</span>
                  </div>
                </div>
                <div
                  v-if="item.is_support_free_shipping != 0"
                  class="free"
                  :class="{'green': config?.shippingStyleV1 }"
                >
                  {{ template(`${Number(item.full_weight).toFixed(2)}g` , language.SHEIN_KEY_PWA_16412) }}
                </div>
              </template>
              <template v-else-if="Number(item.has_zero_threshold) === 0">
                {{ item.starting_price_obj.amountWithSymbol }} 
                <div v-if="Number(item.full_amount.amount) > 0&&showTransportaop&&item.is_support_free_shipping != 0">
                  <span class="product-ship__priceToFree">{{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_28237) }}</span>
                </div>
              </template>
            </template>
          </template> 
          <!-- 多个阶梯价格 -->
          <template v-else-if="shippingDetail(item).length > 1">
            <!-- 免邮门槛和shipping_mold 相同时 -->
            <template
              v-if="(Number(item.shipping_mold) === 2 && Number(item.free_price_num) > 0) ||
                (Number(item.shipping_mold) === 3 && Number(item.full_weight) > 0) ||
                (Number(item.shipping_mold) === 4 && Number(item.full_amount.amount) > 0)"
            >
              <!-- 有效阶梯费用有多个 -->
              <template v-if="effectDetail(item).length > 1">
                <div
                  v-for="(val, index) in effectDetail(item)"
                  :key="index"
                >
                  <template v-if="index > 0">
                    <!-- 阶梯费用开始 -->
                    <template v-if="index === 1">
                      <div> {{ template(effectDetail(item)[0].price.amountWithSymbol, formatUnit(val, item.shipping_mold), moldLang['mold'+item.shipping_mold].less) }} </div>
                    </template>
                    <template v-else>
                      <div> {{ template(effectDetail(item)[index-1].price.amountWithSymbol, formatUnit(effectDetail(item)[index-1], item.shipping_mold), formatUnit(val, item.shipping_mold), moldLang['mold'+item.shipping_mold].between) }} </div>
                    </template>
                    <!-- 最后一个阶梯 -->
                    <template v-if="effectDetail(item).length - 1 === index">
                      <div> {{ template(val.price.amountWithSymbol, formatUnit(val, item.shipping_mold), formatFull(item), moldLang['mold'+item.shipping_mold].between) }} </div>
                      <div
                        v-show="!needHide || (item.shipping_mold != 4 && needHide) && item.is_support_free_shipping != 0"
                        class="free"
                        :class="{'green': config?.shippingStyleV1 }"
                      >
                        {{ template(formatFull(item), moldLang['mold'+item.shipping_mold].free) }}
                      </div>
                    </template>
                  </template>
                </div>
              </template>
              <!-- 有效阶梯费用只有一个或者没有 -->
              <template v-else>
                <div> {{ template(shippingDetail(item)[0].price.amountWithSymbol, formatFull(item), moldLang['mold'+item.shipping_mold].less) }} </div>
                <div
                  v-show="!needHide || (item.shipping_mold != 4 && needHide) && item.is_support_free_shipping != 0"
                  class="free"
                  :class="{'green': config?.shippingStyleV1 }"
                >
                  {{ template(formatFull(item), moldLang['mold'+item.shipping_mold].free) }}
                </div>
              </template>
            </template>
            <!-- 免邮门槛和shipping_mold 不相同时 -->
            <template v-else>
              <div
                v-for="(val, index) in shippingDetail(item)"
                :key="index"
              >
                <template v-if="index > 0">
                  <!-- 阶梯费用开始 -->
                  <template v-if="index === 1">
                    <div> {{ template(shippingDetail(item)[0].price.amountWithSymbol, formatUnit(val, item.shipping_mold), moldLang['mold'+item.shipping_mold].less) }} </div>
                  </template>
                  <template v-else>
                    <div> {{ template(shippingDetail(item)[index-1].price.amountWithSymbol, formatUnit(shippingDetail(item)[index-1], item.shipping_mold), formatUnit(val, item.shipping_mold), moldLang['mold'+item.shipping_mold].between) }} </div>
                  </template>
                  <!-- 最后一个阶梯 -->
                  <template v-if="shippingDetail(item).length - 1 === index">
                    <div> {{ template(val.price.amountWithSymbol, formatUnit(val, item.shipping_mold), moldLang['mold'+item.shipping_mold].upperLimit) }} </div>
                    <template v-if="Number(item.free_price_num) > 0">
                      <div
                        v-if="item.is_support_free_shipping != 0"
                        class="free"
                        :class="{'green': config?.shippingStyleV1 }"
                      >
                        {{ template(formatFull(item), moldLang['mold2'].free) }}
                      </div>
                    </template>
                    <template v-else-if="Number(item.full_weight) > 0">
                      <div
                        v-if="item.is_support_free_shipping != 0"
                        class="free"
                        :class="{'green': config?.shippingStyleV1 }"
                      >
                        {{ template(formatFull(item), moldLang['mold3'].free) }}
                      </div>
                    </template>
                    <template v-else-if="Number(item.full_amount.amount) > 0 && !needHide">
                      <div
                        v-if="item.is_support_free_shipping != 0"
                        class="free"
                        :class="{'green': config?.shippingStyleV1 }"
                      >
                        {{ template(formatFull(item), moldLang['mold4'].free) }}
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </template>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { template } from '@shein/common-function'

export default {
  name: 'ShippingCostsV1',
  props: {
    item: {
      type: Object,
      default: (() => {})
    },
    language: {
      type: Object,
      default: (() => {})
    },
    isShowFree: {
      type: Boolean,
      default: false
    },
    needHide: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({})
    },
    showTransportaop: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {}
  },
  computed: {
    freeLang () {
      return this.language.SHEIN_KEY_PWA_16590
    }, 
    moldLang () {
      return {
        mold2: {
          less: this.language.SHEIN_KEY_PWA_16413,
          between: this.language.SHEIN_KEY_PWA_16414,
          free: this.language.SHEIN_KEY_PWA_16415,
          upperLimit: this.language.SHEIN_KEY_PWA_17435
        },
        mold3: {
          less: this.language.SHEIN_KEY_PWA_16410,
          between: this.language.SHEIN_KEY_PWA_16411,
          free: this.language.SHEIN_KEY_PWA_16412,
          upperLimit: this.language.SHEIN_KEY_PWA_17434
        },
        mold4: {
          less: this.language.SHEIN_KEY_PWA_16408,
          between: this.language.SHEIN_KEY_PWA_16409,
          free: this.language.SHEIN_KEY_PWA_16407,
          upperLimit: this.language.SHEIN_KEY_PWA_17434
        }
      }
    }
  },
  created () {
  },
  methods: {
    template,
    shippingDetail (item) {
      const shippingDetailObj = item.shipping_detail_obj || {}
      const arr = []
      for (var val in shippingDetailObj) {
        arr.push(shippingDetailObj[val])
      }
      return arr  
    },
    effectDetail (item) {
      const detailArr = this.shippingDetail(item)
      const effectArr = []
      detailArr.forEach((val) => {
        if (Number(item.shipping_mold) === 2) {
          if (Number(item.free_price_num) > Number(val.num)) {
            effectArr.push(val)
          }
        } else if (Number(item.shipping_mold) === 3) {
          if (Number(item.full_weight) > Number(val.num)) {
            effectArr.push(val)
          }
        } else if (Number(item.shipping_mold) === 4) {
          if (Number(item.full_amount.amount) > Number(val.num_price.amount)) {
            effectArr.push(val)
          }
        }
      })
      return effectArr
    },
    formatUnit (value, mold) {
      let _value = value.num
      if (Number(mold) === 3) {
        _value = `${_value}g`
      }
      if (Number(mold) === 4) {
        _value = value.num_price && value.num_price.amountWithSymbol
      }
      return _value
    },
    formatFull (item) {
      let fullValue = ''
      if (Number(item.free_price_num) > 0) {
        fullValue = item.free_price_num
      } else if (Number(item.full_weight) > 0) {
        fullValue = `${Number(parseInt(item.full_weight)).toFixed(2)}g` 
      } else if (Number(item.full_amount && item.full_amount.amount) > 0) {
        fullValue = item.full_amount.amountWithSymbol
      }
      return fullValue
    }
  }
}
</script>
<style lang="less" scoped>
.shipping-costs{
  color: var(---sui_color_gray_dark1, #222);
  font-weight: 510;
  .free{
    color: var(--sui-color-safety, #198055);
    font-weight: 700;
  }
}

.product-ship__priceToFree{
  color:#198055;
  font-size: 12px;
  font-weight: 400;
}

</style>
