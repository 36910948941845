<script lang="jsx">
/**
 * @component AddressDrawer
 */

import { Drawer } from '@shein/sui-mobile'
Vue.use(Drawer)

export default {
  name: 'AddressDrawer',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    shippingInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    addressList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isShow: false,
    }
  },
  watch: {
    value (newVal) {
      this.isShow = newVal
    },
    isShow (newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    selectAddress (address) {
      const params = {
        city: address.city,
        city_id: address.cityId,
        state_province: address.state,
        state_id: address.stateId,
        country_id: address.countryId,
        address_id: address.addressId,
        district: address.district,
        district_id: address.districtId
      }
      this.$emit('selectAddress', params)
    },
    selectOtherAddress () {
      this.$emit('goSelectUpper')
    },
  },
  render () {
    return (
      <s-drawer
        class="address-drawer"
        visible={this.isShow}
        on={{ 'update:visible': v => this.isShow = v }}
        direction="btt"
        append-to-body
      >
        <div>
          <header class="address-drawer__header">
            <i
              class="iconfont icon-close"
              onClick={() => this.isShow = false}
            ></i>
            { this.language.SHEIN_KEY_PWA_16266 }
          </header>
          <div class="address-drawer__list">
            <ul>
              {
                this.addressList.map(item => {
                  return (
                    <li
                      class={{ 'address-drawer__item': true, 'address-drawer__selected': this.shippingInfo.address_id == item.addressId }}
                      da-event-click="1-6-4-21"
                      onClick={() => this.selectAddress(item)}
                    >
                      <div class="address-drawer__content">
                        { item.countryId == '108' ?
                          <div>〒 { item.postcode } { item.address1 } { item.address2 }</div> : <div>{ item.address1 } { item.address2 }</div>
                        }
                        { item.countryId == '108' ?
                          <div>
                            { item.countryName } { item.state } { item.city } { item.district }
                          </div> 
                          :  
                          <div>
                            { item.city } { item.state } { item.countryName }  { item.postcode }
                          </div>
                        }
                      </div>
                      { this.shippingInfo.address_id == item.addressId ?
                        <i class="iconfont icon-normal-checked"></i> : null
                      }
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <button
            class="address-drawer__btn"
            da-event-click="1-6-4-22"
            onClick={this.selectOtherAddress}
            domPropsInnerHTML={this.language.SHEIN_KEY_PWA_15080}
          ></button>
        </div>
      </s-drawer>
    )
  }
}
</script>

<style lang="less">
.address-drawer {
  padding: 0 .32rem;
  .left(0);
  &__header {
    width: 100%;
    height: 1.07rem;
    line-height: 1.07rem;
    color: #222;
    .font-dpr(28px);
    text-align: center;
    [class*="iconfont"] {
      position: absolute;
      .left(0);
      top: 0;
      width: 1.07rem;
      height: 1.07rem;
      font-weight: normal;
      color: #999999;
      .font-dpr(24px);
    }
  }
  &__list {
    overflow-y: scroll;
    height: 9.56rem;
    padding: 0 .32rem 1.71rem;
  }
  &__item {
    .flexbox;
    padding: .32rem 0;
    .font-dpr(28px);
    color: #222;
    height: auto;
    border-bottom: 1px solid #E5E5E5;
    align-items: center;
    &.address-drawer__selected {
      font-weight: bold;
      .icon-check{
        .font-dpr(36px);
      }
    }
  }
  &__content {
    flex: 1;
  }
  &__btn {
    position: fixed;
    bottom: 0.16rem;
    width: 9.3rem;
    left: 0.32rem/*rtl:ignore*/;
    right: .32rem/*rtl:ignore*/;
    height: 1.07rem;
    border: 1px solid #222;
    .font-dpr(28px);
    color: #222;
    font-weight: bold;
    background-color: #fff;
    text-transform: uppercase;
  }
}
.c-drawer .address-mshe-mask{
    position: fixed;
    top: 0;
    right: 0/*rtl:ignore*/;
    bottom: 0;
    left: 0/*rtl:ignore*/;
    background-color: rgba(0,0,0,0.6);
    z-index: 999; /* stylelint-disable-line declaration-property-value-blacklist */
    transform: translate3d(0,0,999px);
}
</style>
